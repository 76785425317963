import React, { useReducer, useContext, useEffect } from 'react'
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache } from "aws-amplify"
// import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"
import { listShotwireAssetsDeltas, listAssetsByMarketplaceCategory } from "../graphql/queries"

import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';

import { Flex, Box, jsx } from 'theme-ui'
import { Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import ProductCard from '../@geocine/gatsby-theme-organization/components/ProductCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}


var stripePromise, checkoutSessionId

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const {error, paymentMethod} = await stripe.createPaymentMethod({
//       type: 'card',
//       card: elements.getElement(CardElement),
//     });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe}>
//         Pay
//       </button>
//     </form>
//   );
// };


var setupElements = async function() {
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
  // console.log('stripePromise:', stripePromise)
  // const pubkey = await API.get('shotwireAPI', '/stripe-pub-key', 
  // // {
  //                       // 'queryStringParameters': {
  //                       //   // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
  //                       //   // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
  //                       //   // 'setName': appsContext.state.videoPlayer.assetObj.name,
  //                       //   // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
  //                       //   'jobAction': 'createProxy',
  //                       //   's3srcUrl' : srcObj.srcUrl,
  //                       //   'assetName' : srcObj.name,
  //                       //   'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
  //                       // }
  //                     // }
  //                     );
  //       console.log('pubkey:', pubkey)
  //       return pubkey

  // fetch("/public-key", {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json"
  //   }
  // })
  //   .then(function(result) {
  //     return result.json();
  //   })
  //   .then(function(data) {
  //     stripe = Stripe(data.publicKey);
  //   });
};




// var createCheckoutSession = function(isBuyingSticker) {

//   let apiName = 'shotwireAPI'; // replace this with your api name.
//   let path = '/create-checkout-session'; //replace this with the path you have configured on your API
//   let myInit = {
//       body: JSON.stringify({ isBuyingSticker }),//{}, // replace this with attributes you need
//       headers: {"Content-Type": "application/json"} // OPTIONAL
//   }

//   API.post(apiName, path, myInit).then(response => {
//       // Add your code here
//       console.log('response:',response)
//       return response.json();
//   }).then(data => {
//       // Add your code here
//       console.log('data:',data)
//       checkoutSessionId = data.checkoutSessionId;
//   }).catch(error => {
//       console.log(error.response)
//   });

//         fetch("/create-checkout-session", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json"
//             },
//             body: JSON.stringify({ isBuyingSticker })
//           })
//             .then(function(result) {
//             return result.json();
//           })
//           .then(function(data) {
//             checkoutSessionId = data.checkoutSessionId;
//           });
//   };

const ShotCartPage = () => {
  // let stripe = null 
  let session = null, uGroups, cart, favs, shopCartFilterArray=[], shopCartTotal = 0, cartLicenseOpts=[] //, cartItems = []

  const appsContext = React.useContext(AppsContext)

  // const stripeInit = async () => {
  //   stripe = await loadStripe('pk_test_8iiUqRzMdP7ipyKMn1wHDhyF');
  //   const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
  //   console.log('stripe:', stripe)
  //   appsContext.dispatch({ type: "STRIPE", stripe })
    
  
  // }

  // const stripeSession = async (planId) => {
  //   console.log('stripePromise...', stripePromise)
  //   session = await stripePromise.checkout.sessions.create({
  //     // customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: planId,
  //       }],
  //       trial_from_plan: true,
  //     },
  //     success_url: 'https://video2020.shotwire.com/buysccss',
  //     cancel_url: 'https://video2020.shotwire.com/buycncl',
  //   });

  //   return session
    
  // }

  // (async () => {
  //   const session = await stripe.checkout.sessions.create({
  //     customer_email: 'customer@example.com',
  //     payment_method_types: ['card'],
  //     subscription_data: {
  //       items: [{
  //         plan: 'plan_123',
  //       }],
  //     },
  //     success_url: 'https://example.com/success',
  //     cancel_url: 'https://example.com/cancel',
  //   });
  // })();
  const handleClick = (e, action, id=null) => {
    e.preventDefault()
    // console.log('action:', action)
    // console.log('id:', id)
    if(action === 'remove'){

        cart = Cache.getItem('shotwire-shopCart')
        const expiration = new Date(2020, 12, 18)
        const tmp = cart.filter(item => item !== id)
        // console.log('tmp:', tmp)
        Cache.setItem('shotwire-shopCart', tmp, { expires: expiration.getTime() })
        cart = Cache.getItem('shotwire-shopCart')
        appsContext.dispatch({type:"SHOPCART", shopCart:cart})

    }else if(action === 'pay'){

        // console.log('create stripe session and redirect')
    }else if(action === 'view'){

      // let qstr = ''
      // console.log('id:', id)
      // console.log(appsContext.state.proxyList[fileListIndex])
      // console.log(appsContext.state.proxyList[key].mp4Urls[1])
      // console.log(appsContext.state.proxyList[fileListIndex].thumbNailUrl)
      // const vPlayer = {}
      // vPlayer.image = appsContext.state.archiveList[fileListIndex].thumbNailUrl
      // vPlayer.assetObj= appsContext.state.archiveList[fileListIndex] 
      // appsContext.dispatch({ type: "VPLAYER", vPlayer });

      // if(appsContext.state.archiveList[fileListIndex].realSetId){
      //   qstr = "arsid="+appsContext.state.archiveList[fileListIndex].realSetId+"&asd="+appsContext.state.archiveList[fileListIndex].setDate+"&an="+appsContext.state.archiveList[fileListIndex].name
      // }else qstr = "aid="+appsContext.state.archiveList[fileListIndex].id
      
      navigate(
        "/VideoPlayer/?aid="+id,
        // {
        //   state: { searchform },
        // }
      )

    }else if(action === 'VideoSubscriptions'){
      navigate(
              action,
              // {
              //   state: { searchform },
              // }
            )

          Analytics.record({
                  name: 'VIDEOSUBSCRIPTIONVIEW', 
                  // Attribute values must be strings
                  attributes: { 
                    source: 'ShopCartPage',
                    // video: appsContext.state.videoPlayer.assetObj.name,  
                    // highRezFileName: appsContext.state.videoPlayer.assetObj.highRezFileName, 
                    // setId: appsContext.state.videoPlayer.assetObj.realSetId,
                    // setDate: appsContext.state.videoPlayer.assetObj.setDate,
                    // signedUrl: appsContext.state.videoPlayer.assetObj,
                    // thumbNailUrl: appsContext.state.videoPlayer.assetObj.thumbNailUrl
                  }
                });
    }


  }

  // const getCartTotal = (e, key, productId) => {

  // }

  const handleOnchange = (e, key, assetObj) => {
    e.preventDefault()
    // console.log('e.target.id:',e.target.id)
    // console.log('key:',key)
    // console.log('planId:',e.target)
    // console.log('e.target.value:',e.target.value)
    // console.log('productId:',productId)
    // console.log('licenseProducts:',licenseProducts)
    cartLicenseOpts = appsContext.state.cartLicenseOpts

    
    cartLicenseOpts[key] = {productId: assetObj.id , productName: assetObj.headline , price:licenseProducts[e.target.value].price, priceId:licenseProducts[e.target.value].priceId, licenseTitle:licenseProducts[e.target.value].title , licenseIndex: e.target.value}
    
    // if(!cartLicenseOpts.includes(productId)) cartLicenseOpts.push({})
    
    

    appsContext.dispatch({type:'CARTLICENSEOPTS', cartLicenseOpts: cartLicenseOpts })
    

    // console.log('appsContext.state.cartLicenseOpts:',appsContext.state.cartLicenseOpts)
    getCartTotal()


  }

  const handlePay = (e) => {
    if (e){ 
        e.preventDefault();
      
        // console.log('stripe checkout...')
        // console.log('cartLicenseOpts:', appsContext.state.cartLicenseOpts)

        const lineItems = []
        appsContext.state.cartLicenseOpts.map((item,key)=>{
          // console.log('item:', item)
          lineItems.push({
                        price: item.priceId,
                        // price_data: { currency: 'usd'},
                        // metadata: {'productId':item.productId}, //wrong
                        description: 'License Use: ' +item.licenseTitle + ', Description: ' + item.productName + ', Product ID: ' + item.productId ,
                        quantity: 1,
                    })

        })

        // console.log('stripe lineItems:', lineItems)
        // const sessId = stripeSession(planId)
        // console.log('stripe sessId', sessId)
        // appsContext.dispatch({type:"CHECKOUT",isCheckoutLoading:true})

        Analytics.record({
            name: 'PayShopCart', 
            // Attribute values must be strings
            attributes: { page: "shopcart", cart: appsContext.state.cartLicenseOpts }
        });

        createCheckoutSession(lineItems).then(sessId => {
            // Add your code here
            // console.log('sessId:',sessId)
            // checkoutSessionId = sessId;
            appsContext.dispatch({type:"CHECKOUT", isCheckoutLoading:false})


        })
        

    }
  }
  
  async function createCheckoutSession(lineItems) { 
    let apiName = 'shotwireAPI'; // replace this with your api name.
    let path = '/create-checkout-session'; //replace this with the path you have configured on your API
    
    // console.log('lineItems:', lineItems)

    let myInit = {
                    body: {
                      lineItems: lineItems
                    }, // replace this with attributes you need
                    headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
                 }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            if(result.error){
              // console.log('error:', result.error.message)
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Purchase Error", body: result.error.message }})
            }
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}


async function getShopCartItems(filter) {    
    // console.log('getting shop cart assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        // const albumDataCached = await API.graphql(graphqlOperation(listAssetsByCategory,{ category: category, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
       
       // if(!(filter.length>0)){
       //   shopCartFilterArray = []
       //   // format of graphql filter expresssion for multiple assets
       //   // [{id:{eq:"0ba7f432-1454-43e7-a292-14b9da92d878"}},{id:{eq:"9adf3019-1fbb-4627-bf01-ffa477de43dc"}}]
       //   appsContext.state.shopCart.map((product, key) => {
       //      // const item = { id:{ eq:product } }
       //      shopCartFilterArray.push( { id:{ eq:product } } )
       //   })
       // }
       

       // console.log('filter:', filter)

        if(filter.length>0){
          const cartItems = await API.graphql(
                  {
                      // query: listShotwireAssetsDeltas,
                      query: listAssetsByMarketplaceCategory,
                      variables: { marketplace: "true", filter:{ or: filter } },
                      authMode: ( (appsContext.state.isAdmin===true||appsContext.state.isUploader===true)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM')
                    }
                  );
                
                // console.log('cartItems:', cartItems)
                // return cartItems
                // console.log('albumDataCached:', albumDataCached)
                appsContext.dispatch({ type: "CARTITEMS", cartItems: cartItems.data.listAssetsByMarketplaceCategory.items });
        
                cartItems.data.listAssetsByMarketplaceCategory.items.forEach((item, i) => {
                      // console.log('item::',item)
                      // console.log('i:',i)
                      cartLicenseOpts[i] = {productId: cartItems.data.listAssetsByMarketplaceCategory.items[i].id , productName: cartItems.data.listAssetsByMarketplaceCategory.items[i].headline, price:licenseProducts[0].price, priceId:licenseProducts[0].priceId, licenseTitle:licenseProducts[0].title , licenseIndex: 0}
                      // cartLicenseOpts[key] = {productId: assetObj.id , productName: assetObj.headline , price:licenseProducts[e.target.value].price, priceId:licenseProducts[e.target.value].priceId, licenseTitle:licenseProducts[e.target.value].title , licenseIndex: e.target.value}
    
        
                })
                // console.log('cartLicenseOpts:', cartLicenseOpts)
                appsContext.dispatch({type:'CARTLICENSEOPTS', cartLicenseOpts: cartLicenseOpts})
        }else{
            appsContext.dispatch({ type: "CARTITEMS", cartItems: [] })
            appsContext.dispatch({type:'CARTLICENSEOPTS', cartLicenseOpts: []})
        }

        // return 'done'
        
        // console.log('albumsByCategory:', appsContext.state.albumsByCategory)
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('get shop cart err: ', err)
      }      
  }

  function getCartTotal(items){
      //get initial cart items and iterate the default license
      // licenseProducts.forEach()
      // console.log('appsContext.state.shopCart:', appsContext.state.shopCart)
      // console.log('getCartTotal items:', items)
      // console.log('appsContext.state.cartLicenseOpts:', appsContext.state.cartLicenseOpts)

      var sum = appsContext.state.cartLicenseOpts.reduce( function (total, licenseOpts) {
        // console.log('licenseOpts:', licenseOpts)
        // console.log('total:', total)


        return total + Math.round(licenseOpts.price);
      }, 0);
      // console.log('sum:', sum)
      appsContext.dispatch({type:'SHOPCARTTOTAL', shopCartTotal:sum})

      // return sum
  }

  useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')
          setupElements()

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])

  useEffect(() => {
      // console.log('appsContext.state.cartLicenseOpts->',appsContext.state.cartLicenseOpts)

      getCartTotal()

      // console.log('appsContext.state.shopCartTotal:', appsContext.state.shopCartTotal)

      // appsContext.dispatch({type:'SHOPCARTTOTAL',shopCartTotal:shopCartTotal})

      // var sum = appsContext.state.cartLicenseOpts.reduce(function (total, licenseOpts) {
      //   return total + Math.round(licenseOpts.price);
      // }, 0);
      // console.log('sum:', sum)
      // appsContext.state.cartLicenseOpts.forEach((key) => (appsContext.state.cartLicenseOpts[key].price > 0) && (shopCartTotal=shopCartTotal + appsContext.state.cartLicenseOpts[key].price)) : 0

  },[ appsContext.state.cartLicenseOpts ])

	useEffect(() => {
		      try{
            let isMounted = true
            if(isMounted === true){

              // setupElements()
              cart = Cache.getItem('shotwire-shopCart')
              appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 
              favs = Cache.getItem('shotwire-favorites')
              appsContext.dispatch({type:"FAVORITES", favorites:favs})

              // console.log('shopCartFilterArray:', shopCartFilterArray)

              appsContext.state.shopCart.map((product, key) => {
                // console.log('product:', product)
                // console.log('key:', key)
              // const item = { id:{ eq:product } }
                shopCartFilterArray.push( { id:{ eq:product } } )
              })

              // console.log('shopCartFilterArray:', shopCartFilterArray)
              
              const items = getShopCartItems(shopCartFilterArray)
              // console.log('items=>',items)
              // console.log('appsContext.state.cartLicenseOpts.',appsContext.state.cartLicenseOpts)
              getCartTotal(items)
              // console.log('appsContext.state.cartLicenseOpts..',appsContext.state.cartLicenseOpts)
              // console.log('appsContext.state.shopCartTotal=',appsContext.state.shopCartTotal)
             
           }
           
             if(appsContext.state.user){
              // const  userInfo  = appsContext.state.user
              // console.log('userInfo: ', userInfo)
              // const uRoles = userInfo.attributes[`custom:roles`].split(',')
              //user roles checks
              // if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
             }

            
          // createCheckoutSession();
          // stripeInit()
          // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // getCachedAssetData()
              if( appsContext.state.isAdmin ){
                // console.log('live data...')
                // getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  //remove shop cart

                  
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
		}, [ appsContext.state.shopCart.length ]);




  //cost from $.01@SD - $.06@4k / min of converted footage depending on resolution and total fps processed.
 const licenseProducts = [
        {
          title: "HD - Web/Digital",
          titleBgColor: "rgba(51, 75, 135, .55)",
          subtitle: "",
          price: "150",
          priceId: "price_HMAlifnmI1eYJw", //"plan_GksirIDuQ30TXw",
          // planIdAnnual: "plan_HC5zE926WewpA0", //"plan_GktBYQnFayBrVP",
          public: true,
          description: ""
          
        },
        {
          title: "HD - Film/TV",
          titleBgColor: "rgba(51, 75, 135, .7)",
          subtitle: "",
          price: "200",
          priceId: "price_HMAlMeJD86Zmwf", //"plan_GktA5KJR6EDMHV",
          // planIdAnnual: "plan_HC5zmqNYRfE2Ry", //"plan_GktBYQnFayBrVP",
          public: true,
          description: ""
          
        },
        {
          title: "HD - in Game",
          titleBgColor: "rgba(51, 75, 135, .85)",
          subtitle: "",
          price: "600",
          priceId: "price_HMAlLpBP42faUt",   //"plan_GktAanV1vB4DyD",
          // planIdAnnual: "plan_HHKWXwJv9VWSxW", //"plan_GktBYQnFayBrVP",
          public: true,
          description: ""
          
        },
 ]



  return(
  	
<Layout>
  <Helmet>
    <title>Shotwire Cart</title>
    <link rel="icon" href={favicon} type="image/x-icon" />
    
  </Helmet>

 <Container>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h1>Your Cart</h1>
 
 </div>
 </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '.9rem'

 }}>
 <p>
 
</p>
 </Col>
 </Row>

        
        { 

          appsContext.state.cartItems.map((file,key) => (

            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#e8efff',
                color: colorBlack80
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={6}><b>{key+1}.</b> {file.name}<br />
              {(file.thumbNailUrl!==null) ? 
                <div>
                  
                  <Image 
                  src={file.thumbNailUrl}
                  alt={file.name}
                  height={168}
                  // width={295}
                  // width={'100%'}
                  // width={ (assetId=='none') ? `300` : '100%'}
                  // fluid
                  />
                  </div>

                : <b>MISSING THUMBNAIL</b>}
            </Col>
          
           
          
            <Col sm={12} lg={6}>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'view',file.id)}>View</Button> 
                
              
                {
                // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handlePublish(e, key)} disabled>Publish</Button> 
                }
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleClick(e, 'remove', file.id)}>
                Remove
                </Button>
                
            </Col>
            <Col>
              
              <Form.Group controlId={"price"+"_"+key}>
            <Form.Label>Select Your License Use Option:</Form.Label>
            <Form.Control as="select" value={typeof appsContext.state.cartLicenseOpts[key]!=='undefined'?appsContext.state.cartLicenseOpts[key].licenseIndex:''} onChange={e => handleOnchange(e,key,file)}>
            
        {
          licenseProducts.map((license, i) => (
// console.log('i:',i)
              <option value={i} key={i}>{license.title} - ${license.price}</option>

            ))

        }
        </Form.Control>
        </Form.Group>



            </Col>
            <Col sx={ {textAlign: 'right'}}>
            Price: ${(typeof appsContext.state.cartLicenseOpts[key] !== 'undefined') ? appsContext.state.cartLicenseOpts[key].price: 0}.00
            </Col>
          </Row>
          
          ))
          // cloudProducts.map((product, key) => {
          //             const description = product.description.split(';')
          //             // console.log('description:', description)

          //             return (
          //                 <Box sx={{ width: ['100%', '50%', '20%'], px: 2, py: 2 }} key={key}>
          //                   <ProductCard assetObj={product} assetId={product.assetId} name={ product.title } titleBgColor={ product.titleBgColor } href="#!" image=''>
          //                      {product.subtitle}
                          
          //                       <div style={{fontSize:'1rem', color: colorBlack80}}>
                                
          //                         <ListGroup variant="flush">
                                
                                
          //                       {
          //                         description.map((line,key) => (

          //                             // <span>{line}</span>

          //                             // <li role="listitem" class="">{line}</li>
          //                             <ListGroup.Item key={key}>{line}</ListGroup.Item>
          //                          ))                                            
          //                       }
                                
                                
          //                         </ListGroup>
                                
                                

          //                       </div>
          //                       <div>
                                
          //                       <span style={{fontSize:'1.3rem', color: colorBlack80}}>
          //                       {
          //                         // (/*appsContext.state.user!==null && */product.public!==false)?product.price:"Log In For Pricing"
          //                       (appsContext.state.user!==null) ? 
          //                       <div sx={{ color: colorBlack80}}>
          //                         {product.price}
          //                         <br />
          //                         <span style={{fontSize:'.8rem'}}>{product.priceNotes}</span> 
          //                         </div> 
          //                       : 
          //                       ( 
          //                         product.public ? 
          //                         <div sx={{ color: colorBlack80}}>
          //                         {product.price}
          //                         <br />
          //                         <span style={{fontSize:'.8rem'}}>{product.priceNotes}</span> 
          //                         </div>
          //                         : 
          //                         "Log In For Pricing & Schedule Setup" 
          //                         )
                                  
          //                         }
          //                       </span>
          //                       </div>
          //                       <div>
          //                       <Button 
          //                         id="playButton" 
          //                         // variant="info" 
          //                         onClick={e => handleBuy(e, product.planId)}
          //                         disabled={(appsContext.state.user!==null) ? false:!product.public}
          //                         sx={{backgroundColor:"rgba(89, 130, 235, 1)"}}
          //                         >   
          //                         {
          //                           (appsContext.state.isCheckoutLoading) 
          //                           ? 
          //                             (
          //                               <span> Checkout <FontAwesomeIcon
          //                                   icon={faSpinner}
          //                                   size="sm"
          //                                   sx={{
          //                                     // marginRight: '0.5rem'
          //                                   }}
          //                                   spin
          //                                 /> 
          //                               </span>
          //                             )
                                      
          //                             : 
                                      
          //                             (appsContext.state.user!==null) 
          //                             ? 
          //                             (product.buyButton) ? <b>{product.buyButton}</b>:<b>BUY NOW</b> 
          //                             : 
          //                             ( product.public ? <b>BUY NOW</b> : <b>LOG IN TO PURCHASE & SCHEDULE SETUP</b> )
                                 
          //                         }
          //                        {
          //                           // (appsContext.state.user!==null) ? <b>BUY NOW</b> : ( !product.public ? <b>BUY NOW</b> : <b>LOG IN TO PURCHASE</b> )
          //                         } 
          //                       </Button>
          //                       <br />
          //                       {
          //           (appsContext.state.user!==null) ? <span sx={{fontSize:'.8rem', color: colorBlack80}}>{product.terms}</span> : null
          //          } 
          //                       </div>
          //                       <div>
          //                       <span style={{ color: 'gray' }}>2 Months Free!<br />with Annual Pay</span><br />
          //                       {
          //                       (appsContext.state.user!==null) ? <a href="#!" onClick={e => handleBuy(e, product.planIdAnnual)}>Click here for Annual Pay</a>: ( product.public ? <a href="#!" onClick={e => handleBuy(e, product.planIdAnnual)}>Click here for Annual Pay</a> : null )
          //                      }
                                
          //                       </div>
          //                   </ProductCard>
          //                 </Box>
          //               )
          //             }
          // )
        }
 
  <Row sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#e8efff',
                color: colorBlack80,
                fontSize: '1.1rem'
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
          <Col sm={12} lg={6}>
          Total: 
          </Col>
          <Col sm={12} lg={6} sx={ {textAlign: 'right'}}>
          {(typeof appsContext.state.shopCartTotal!=='undefined'||appsContext.state.shopCartTotal!==null) ? <span>${appsContext.state.shopCartTotal}.00</span> : <span>N/A</span> }
          </Col>
         
      </Row> 
<Row sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#e8efff',
                color: colorBlack80,
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
                      <Col lg={12} sx={ {textAlign: 'right'}}>
      <Button sx={{ margin: '2px' }} variant="primary" href="#!" size="md" onClick={e => handlePay(e, 'pay')}>PAY NOW</Button>      
   </Col>
          <Col lg={12} sx={ {textAlign: 'right'}}><span sx={{padding:'5px'}}>Buying more than one license?</span>
      <Button sx={{ margin: '2px' }} variant="primary" href="#!" size="md" onClick={e => handleClick(e, 'VideoSubscriptions')}>BUY SUBSCRIPTION PLAN</Button>      
   </Col>
   </Row>
   <Row>
     <Col sx={{
      // paddingLeft: '10px',
      // padding: '20px 0px 20px',
      marginTop: '20px',
      marginBottom: '20px',
      fontSize: '.8rem'

     }}>
    <b><sup>*</sup> Per AWS, Resolutions for both the Basic and Professional tiers are defined as:</b>
    <p>
    <b>SD</b> is an output of less than 720 resolution.
    <br />
    <b>HD</b> is an output of greater than or equal to 720 resolution, but less than or equal to 1080 resolution.
    <br />
    <b>4K</b> is an output of greater than 1080, but less than or equal to 2160 resolution.
    <br />
    <b>8K</b> is an output of greater than 2160, up to and including 4320 resolution.
    </p>
    <p>
    For outputs with a landscape orientation, resolution is measured vertically.
    <br />
    For outputs with a portrait orientation, resolution is measured horizontally.
    </p>
    </Col>
   </Row>

      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default ShotCartPage