import React from 'react'
/** @jsx jsx */
import { Cache } from "aws-amplify"
import { Link, navigate } from 'gatsby'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'
import { jsx } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay , faHeart, faStar, faEdit } from "@fortawesome/free-solid-svg-icons";

import Image from 'react-bootstrap/Image'

// import awsconfig from "../../../aws-exports";
// import { AppsContext } from '../../../AppsProvider'

const StyledCard = styled(Card)`
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  height: 100%;
  text-align: center;
`

    // <Card bg="info" text="white" style={{ width: '18rem' }}>


const shotwireGray = '#e9e4e3'
const buttonColor = '#1b2f63'

 // state={{ assetId: assetId, assetName: name, image: image, assetObj: assetObj }}
const ProductCard = ({ user, platform, assetObj, assetId, name, titleBgColor, href, image, children }) => {
  let isAdmin = false, userInfo = null, uRoles = null, favs = []

  // console.log('assetObj:', assetObj)
  // const appsContext = React.useContext(AppsContext)

  // if(appsContext.state.user){
  //   userInfo  = appsContext.state.user
  //   // console.log('userInfo: ', userInfo)

  //   uRoles = userInfo.attributes[`custom:roles`].split(',')
  //   // console.log('uRoles: ', uRoles)
  //   if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) isAdmin = true
  //   // console.log('isAdmin:', isAdmin)
  
  //   favs = Cache.getItem('favorites')
  //   // console.log('favs>', favs)

  // }



  function getItemCacheCallback(assetId){
    return([assetId])
  }

  const handleClick = (e, arg) => {
    if (e){ 
      if(arg === 'playVideo'){
        
          // vPlayer.image = (assetObj.thumbNailUrl != null)?assetObj.thumbNailUrl:image
          // vPlayer.assetObj= assetObj 
          // appsContext.dispatch({ type: "VPLAYER", vPlayer });
          // if(e.currentTarget.id === 'playButton') navigate(
          //     "/VideoPlayer",
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
      }

    }
  }


  return (

    <StyledCard bg="" border="">
      <Card.Header as="h5" sx={{backgroundColor:titleBgColor,color:"white"}}>{name}</Card.Header>
      <Card.Body>
       
        {children}

        {
          (platform==="enterprise")?
          <span style={{ color: 'gray' }}>30-day money back guarantee</span>
          : 
          <span style={{ color: 'gray' }}>7-day money back guarantee</span>
        }
             
        {
          // <Button variant="dark" onClick={e => handleClick(e, 'addFavorite')}>
          //         {
          //           (appsContext.state.favorites.includes(assetId)) ? 
          //           <FontAwesomeIcon
          //             id={'fav_'+assetId}
          //             icon={faStar}
          //             size="sm"
          //             sx={{
          //               // marginRight: '0.5rem'
          //               color: 'red'
          //             }}
          //           />
          //           :
          //           <FontAwesomeIcon
          //             id={'fav_'+assetId}
          //             icon={faStar}
          //             size="sm"
          //             sx={{
          //               // marginRight: '0.5rem'
          //               // color: 'red'
          //             }}
          //           />
          //         }
        
          //       </Button>
              }
        {' '}
      { 
        // (isAdmin===true) ?
        // (
        //   <Button variant="dark" onClick={e => handleClick(e, 'editMeta')}>
        //   <FontAwesomeIcon
        //     icon={faEdit}
        //     size="sm"
        //     sx={{
        //       // marginRight: '0.5rem'
        //     }}
        //   />
        // </Button>
        // )
        // : ('')
      }
    

      </Card.Body>
         </StyledCard>

)

}
/*
 <Image 
                  src={require(`${image}`)}
                  alt="avalon-pacificcoastnews-video"
                  height={168}
                  width="100%"
                  rounded
                />
/*
 <FontAwesomeIcon
          icon={faVideo}
          size="lg"
          sx={{
            marginRight: '0.5rem'
          }}
        />
        */

export default ProductCard
